import base from './links'
import generateRouteData from './generateRouteData'
import generateAbsoluteLinks from './generateAbsoluteLinks'


export { default as useModalParams } from './useModalParams'
export { default as getLinkWithParams } from './getLinkWithParams'
export { default as base } from './links'

export const routeData = generateRouteData(base)

// ATTN it's required for auto-import
const links = generateAbsoluteLinks(base)

export default links
