type Data = {
  title: string
  type?: 'vue'
  tag: UserModel.ModuleTag
}

type Result = Record<string, Data>

// ATTN used in getLinksConfig
const generateRouteData = (links): Result => {
  const routesData = {}

  const getRouteData = (link, rootData) => {
    if (link.title) {
      const path = `${rootData?.path || ''}/${link.path}`

      const data = {
        title: link.title,
        type: link.type || rootData?.type,
        tag: link.tag || rootData?.tag,
      }

      routesData[path] = data

      return {
        ...data,
        path,
      }
    }
    else if (link.root) {
      const { root, ...links } = link

      const _rootData = getRouteData(link.root, rootData)

      Object.keys(links).map((key) => {
        getRouteData(links[key], _rootData)
      })
    }
    else if (rootData && link.path) {
      const path = `${rootData?.path || ''}/${link.path}`

      routesData[path] = rootData

      return {
        ...rootData,
        path,
      }
    }
    else {
      console.error('No root', link)
    }
  }

  Object.keys(links).forEach((key) => {
    getRouteData(links[key], null)
  })

  return routesData
}


export default generateRouteData
