import { useMemo } from 'react'
import { usePathname } from 'router'


const cache = {}

// Needed for modals where router context is not exist
// Supports only one param at the end of the url: link/:param
const useModalParams = (link: string) => {
  const pathname = usePathname()

  return useMemo(() => {
    if (!cache[pathname]) {
      const paramName = link.replace(/.*:/, '')
      const linkName = link.replace(`/:${paramName}`, '')
      const value = pathname.replace(`${linkName}/`, '')

      cache[pathname] = {
        [paramName]: value,
      }
    }

    return cache[pathname]

  }, [ pathname, link ])
}


export default useModalParams
